import bg3 from "public/images/home/LuxuryRafflePools.png";
import PoolCard from "./pool-card";

import { Money } from "@quint/components";
import { formatBigNumber } from "@quint/services/web3";
import { useTotalLuxuryStakedQuints } from "@quint/services/web3/hooks";

const TotalStaked = () => {
  const query = useTotalLuxuryStakedQuints();

  return (
    <Money
      value={formatBigNumber(query.data)}
      className="font-bold text-primary text-lg"
      isLoading={query.isLoading}
    />
  );
};

const Footer = () => {
  return (
    <div className="flex items-center gap-[11px]">
      <p className="text-md">Staked</p>
      <TotalStaked />
    </div>
  );
};
const LuxuryPools = () => {
  return (
    <PoolCard
      title="Luxury Raffle Pools"
      subTitle="Get a chance to win real world luxury prizes"
      bgImage={bg3}
      href="/luxury"
      cardFooter={<Footer />}
    />
  );
};

export default LuxuryPools;
