import { PoolCard } from "@quint/features/home";

import bg2 from "public/images/home/QuintessentialPools.png";

import ConventionalPools from "./conventional";
import LuxuryPools from "./luxury";

export const PoolsCards = () => {
  return (
    <div className="grid grid-cols-1 justify-between gap-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      <ConventionalPools />
      <PoolCard
        title="Quintessential Pools"
        subTitle="Exotic holidays & much more"
        bgImage={bg2}
        href="/"
        cardFooter={<p className="text-base">Coming Soon</p>}
        isDisabled
      />
      <LuxuryPools />
    </div>
  );
};
