import type { ImageProps } from "@quint/ui";
import { Image } from "@quint/ui";

("use client");

interface BackgroundImageProps extends Partial<ImageProps> {
  image: ImageProps["src"];
}

const BackgroundImage = ({ image, ...rest }: BackgroundImageProps) => {
  return (
    <div className="absolute z-[-1] h-full w-full overflow-hidden">
      <Image
        src={image}
        alt="bg image"
        fill
        priority
        placeholder="blur"
        className="object-cover"
        quality={100}
        loading="eager"
        // sizes="100vw"
        // style={{
        //   objectFit: "cover",
        // }}
        sizes="(max-width: 1024px) 100vw,
        (max-width: 1280px) 48vw,
        30vw"
        {...rest}
      />
    </div>
  );
};

BackgroundImage.displayName = "BackgroundImage";

export default BackgroundImage;
