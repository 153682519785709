import { cn, type ImageProps } from "@quint/ui";
import Link, { type LinkProps } from "next/link";
import { type ReactElement } from "react";

import BackgroundImage from "./bg-image";

export type PoolCardProps = {
  title: string;
  subTitle: string;
  bgImage: ImageProps["src"];
  cardFooter: ReactElement;
  href: LinkProps["href"];
  isDisabled?: boolean;
};

function PoolCard({ title, subTitle, bgImage, href, cardFooter, isDisabled = false }: PoolCardProps) {
  return (
    <Link
      href={href}
      className={cn(isDisabled ? "pointer-events-none cursor-not-allowed" : "cursor-pointer")}
      aria-disabled
    >
      <div
        className={
          "relative flex h-96 w-full flex-1 flex-col justify-between overflow-clip rounded-lg text-white md:min-w-[384px]"
        }
      >
        <BackgroundImage
          image={bgImage}
          sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
        />
        <div className="flex flex-col p-6">
          <h1 className="font-satoshi font-bold text-4xl">{title}</h1>
          <h2 className="font-satoshi font-normal text-base">{subTitle}</h2>
        </div>
        <div className="flex h-[90px] items-center bg-white/20 p-6 backdrop-blur-[2px]">{cardFooter}</div>
      </div>
    </Link>
  );
}

PoolCard.displayName = "PoolCard";

export { PoolCard };

export default PoolCard;
