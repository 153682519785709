import { Button } from "@quint/ui";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@quint/ui/menu";
import { MoreVertical } from "lucide-react";
import Link from "next/link";
import { type Address } from "wagmi";

type Props = {
  address: Address;
  disabled?: boolean;
};

const PoolOptions = (props: Props) => {
  const { address, disabled } = props;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button icon variant="text" as="div" aria-label="Open context menu">
          <MoreVertical />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end">
        <DropdownMenuItem role="button" tabIndex={0} className="cursor-pointer" asChild>
          <Link href={`https://bscscan.com/address/${address}`} target="_blank">
            View Explorer
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PoolOptions;
