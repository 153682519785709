import { Image } from "@quint/ui";
import coinsShadow from "public/images/home/coins-shadow.png";
import coins from "public/images/home/coins.png";

import { formatBigNumber } from "@quint/services/web3";

import { Money } from "@quint/components";
import { useTotalStakedQuints, useTotalStakedQuintsTVL } from "@quint/services/web3/hooks";

const TotalStaked = () => {
  const query = useTotalStakedQuints();

  return (
    <Money value={formatBigNumber(query.data)} className="font-bold text-primary text-lg" isLoading={query.isLoading} />
  );
};
const TotalTVL = () => {
  const query = useTotalStakedQuintsTVL();

  return (
    <Money
      value={formatBigNumber(query.data)}
      className="font-bold text-primary text-lg"
      isLoading={query.isLoading}
      currency="$"
    />
  );
};
export const HomeHero = () => {
  return (
    <div className="relative flex w-full flex-col gap-8 lg:flex-row">
      <div className="flex flex-col gap-4 text-center lg:text-left">
        <div className="font-satoshi font-bold text-8xl">Quint Super Staking Platform</div>
        <div className="font-sans font-normal text-xl">Unlock bigger rewards and passive income with super staking</div>
        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row lg:justify-start lg:gap-[29px]">
          <div className="flex items-center gap-[11px]">
            <p className="text-base">Staked</p>
            <TotalStaked />
          </div>
          <div className="h-full w-[1px] bg-[#d9d9d94d]" />
          <div className="flex items-center gap-[11px]">
            <p className="text-base">TVL</p>
            <TotalTVL />
          </div>
        </div>
      </div>

      <div className="relative flex w-[100%] flex-col items-center justify-center md:w-[100%] xl:w-[70%]">
        <Image
          src={coins}
          alt="coins"
          quality={100}
          loading="eager"
          priority
          sizes="(max-width: 1024px) 100vw,
            (max-width: 1280px) 40vw,
            540px"
        />
        <Image
          src={coinsShadow}
          className="relative top-[-150px] right-[-150px] z-[-1] mix-blend-multiply lg:top-[-20px] lg:right-0 lg:left-[-50px]"
          quality={100}
          priority
          loading="eager"
          alt="lights"
        />
      </div>
    </div>
  );
};
