import Layout from "@quint/components/layout";
import { HomeHero, PoolsCards } from "../features/home";

import { cn, Image } from "@quint/ui";
import { useHeaderPadding } from "@quint/utils/hooks";
import heroBg from "public/images/home/hero-bg.png";
import lights from "public/images/home/lights.png";
import stars from "public/images/home/stars.png";

function Main() {
  const headerPadding = useHeaderPadding();
  const pageTopMargin = "mt-[40px] md:mt-[55px]";
  return (
    <Layout includeHeaderPadding={false}>
      <div className="relative flex w-full items-center justify-center overflow-hidden">
        <Image
          src={heroBg}
          className={cn(
            "absolute top-0 z-[-1] min-w-[1916px] opacity-20 mix-blend-overlay ",
            headerPadding,
            pageTopMargin,
            "mt-[160px]"
          )}
          quality={100}
          priority
          loading="eager"
          alt="squares"
          sizes="(max-width: 768px) 340vw,
          (max-width: 1900px) 240vw,
          100vw"
        />
        <Image
          src={lights}
          className="absolute top-[-60px] z-[-1] min-w-[1916px] opacity-20 mix-blend-color-dodge"
          priority
          quality={100}
          loading="eager"
          alt="lights"
        />
        <Image
          src={stars}
          className="absolute top-0 z-[-1] min-w-[170%] mix-blend-color-dodge lg:right-0 lg:top-[-60px] lg:w-[60%] lg:min-w-0"
          quality={100}
          priority
          loading="eager"
          alt="lights"
          sizes="(max-width: 768px) 100vw, 50vw"
        />
        <div className={cn("x-padding y-padding relative flex w-full flex-col gap-36", headerPadding, pageTopMargin)}>
          <HomeHero />
          <PoolsCards />
        </div>
      </div>
    </Layout>
  );
}

export default Main;
