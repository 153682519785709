"use client";
import { Loader, cn } from "@quint/ui";
import { useMounted } from "@quint/utils";
import { formatMoney } from "@quint/utils/format-money";

type Props = {
  value: string;
  isLoading: boolean;
  currency?: string;
  className?: string;
  digits?: number;
};

const Money = (props: Props) => {
  const { value, isLoading, currency = "QUINT", className, digits = 2, ...rest } = props;
  const { hasMounted } = useMounted();

  return (
    <span className={cn("flex flex-row items-center justify-center gap-2", className)} {...rest}>
      {!hasMounted || isLoading ? <Loader /> : formatMoney(value, digits)}
      {" " + currency}
    </span>
  );
};

export default Money;
export { Money };
