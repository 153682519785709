import { Money } from "@quint/components";
import { formatBigNumber } from "@quint/services/web3";
import { useTotalConventionalStakedQuints } from "@quint/services/web3/hooks";
import bg1 from "public/images/home/ConventionalPools.png";
import PoolCard from "./pool-card";

const TotalStaked = () => {
  const query = useTotalConventionalStakedQuints();

  return (
    <Money value={formatBigNumber(query.data)} className="font-bold text-primary text-lg" isLoading={query.isLoading} />
  );
};

const Footer = () => {
  return (
    <div className="flex items-center gap-[11px]">
      <p className="text-md">Staked</p>
      <TotalStaked />
    </div>
  );
};
const ConventionalPools = () => {
  return (
    <PoolCard
      title="Conventional Pools"
      subTitle="Crypto Rewards"
      bgImage={bg1}
      href="/conventional"
      cardFooter={<Footer />}
    />
  );
};

export default ConventionalPools;
